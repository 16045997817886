<template>
  <div style="min-height: 106px;">


    <div class="d-flex mt-4">
      <div class="w-100" v-if="inContentTemp('invoice_code') && data.invoice_code">
        <div class="green-cc">
          <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>رقم الفاتورة</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice No.</b></h5>
          <p class="mb-0 space-latter-2">{{ data.invoice_code }}</p>
        </div>
      </div>
      <div class="ml-2 mr-2 w-100" v-if="inContentTemp('reference_number') && data.reference_number">
        <div class="green-cc">
          <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>الرقم المرجعي</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">PO No.</b></h5>
          <p class="mb-0 space-latter-2">{{ data.reference_number }}</p>
        </div>
      </div>
      <div class="ml-2 mr-2 w-100" v-if="inContentTemp('invoice_date') && data.invoice_date">
        <div class="green-cc">
          <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>تاريخ الفاتورة</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice date</b></h5>
          <p class="mb-0 space-latter-2">{{ data.invoice_date }}</p>
        </div>
      </div>
      <div class="ml-2 mr-2 w-100" v-if="inContentTemp('issue_date') && data.issue_date">
        <div class="green-cc">
          <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>تاريخ الإستحقاق</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Due date</b></h5>
          <p class="mb-0 space-latter-2">{{ data.issue_date }}</p>
        </div>
      </div>
      <div class="ml-2 mr-2 w-100" v-if="inContentTemp('is_deferred')">
        <div class="green-cc">
          <h5 class="color-1a5a4a mb-1 f-s-15px d-flex justify-content-between"><b>نوع الفاتورة</b> <b v-if="is_multi_language_label" style="display: block; direction: ltr;">Invoice type</b></h5>
          <p v-if="data.is_deferred" class="mb-0 space-latter-2">آجل</p>
          <p v-else class="mb-0 space-latter-2">نقدي</p>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="mt-2 w-100  margin-left-025rem">
        <div class="d-flex justify-content-between">
          <span class="color-1a5a4a f-s-15px mb-1"><b>معلومات الـبـــــائع</b></span>
          <span class="color-1a5a4a f-s-15px mb-1" v-if="is_multi_language_label"><b>Seller information</b></span>
        </div>

        <div class="w-60per h-100">
          <div class="green-cc-3">
            <div class="d-flex">
              <span class="par-h"></span>
              <div>
                <p class="f-s-15px mb-1" v-if="inContentTemp('business_name_ar')">{{ data.company ? data.company.business_name : '' }}</p>
                <p class="f-s-15px mb-1" v-if="inContentTemp('business_name_en')">{{ data.company ? data.company.business_name_en : '' }}</p>
                <p class="f-s-15px mb-0" v-if="data.company">
                  <span v-if="inContentTemp('cocompany_mpany_country_name')">{{data.company.country_name}}</span>
                  <span v-if="inContentTemp('cocompany_mpany_city_name')">{{(data.company.city_name ? ' - ' + data.company.city_name : '')}}</span>
                  <span>{{(data.company.address_1 ? ' - ' + data.company.address_1 : '')}}</span>
                </p>
              </div>
            </div>
            <div v-if="data.company && (data.company.business_record || data.company.tax_no)">
                <h5 class="f-s-15px mb-0" v-if="data.company.business_record && inContentTemp('business_record')">
                  <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم السجل التجــــاري</span> <span>{{ data.company ? data.company.business_record : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">CR No.</span></p>
<!--                  <p class="mb-0 f-s-15px">{{ data.company ? data.company.business_record : '' }}</p>-->
                </h5>
                <h5 class="f-s-15px mb-0" v-if="data.company.tax_no && inContentTemp('tax_no')">
                  <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم التسجيل الضريبي</span> <span>{{ data.company ? data.company.tax_no : '' }}</span>  <span style="display: block; direction: ltr;" v-if="is_multi_language_label">VAT No.</span></p>
<!--                  <p class="mb-0 f-s-15px">{{ data.company ? data.company.tax_no : '' }}</p>-->
                </h5>
            </div>

          </div>
        </div>

      </div>
      <div class="mt-2 w-100 margin-right-025rem">
        <div class="d-flex justify-content-between">
          <span class="color-1a5a4a f-s-15px mb-1"><b>معلومات المشتري</b></span>
          <span class="color-1a5a4a f-s-15px mb-1" v-if="is_multi_language_label"><b>Buyer information</b></span>
        </div>
        <div class="w-60per h-100">
          <div class="green-cc-3">
            <div class="d-flex">
              <span class="par-h"></span>
              <div>
                <p class="f-s-15px mb-1" v-if="inContentTemp('fullname')">{{ data.customer ? data.customer.fullname : '' }}</p>
                <p class="f-s-15px mb-0">{{ data.customer ? (data.customer.country_name) + (data.customer.city_name ? ' - ' + data.customer.city_name : '') + (data.customer.address_1 ? ' - ' + data.customer.address_1 : '') : '' }}</p>
              </div>
            </div>
            <div class="ml-2 mr-2" v-if="data.customer && (data.customer.commercial_register || data.customer.tax_register)">

                <h5 class="f-s-15px mb-0" v-if="data.customer.commercial_register && inContentTemp('commercial_register')">
                  <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم السجل التجــــاري</span> <span>{{ data.customer ? data.customer.commercial_register : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">CR No.</span></p>
<!--                  <p class="mb-0 f-s-15px">{{ data.customer ? data.customer.commercial_register : '' }}</p>-->
                </h5>
                <h5 class="f-s-15px mb-0" v-if="data.customer.tax_register && inContentTemp('tax_register')">
                  <p class="color-1a5a4a mb-1 d-flex justify-content-between"><span>رقم التسجيل الضريبي</span> <span>{{ data.customer ? data.customer.tax_register : '' }}</span> <span style="display: block; direction: ltr;" v-if="is_multi_language_label">VAT No.</span></p>
<!--                  <p class="mb-0 f-s-15px">{{ data.customer ? data.customer.tax_register : '' }}</p>-->
                </h5>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2 d-flex" v-if="data.notes">
      <div class="w-60per">
        <div class="green-cc-3" style="height: auto;">
          <span class="color-1a5a4a f-s-15px mb-4 d-flex justify-content-between"><b>الملاحظات</b> <b v-if="is_multi_language_label">Notes</b></span>
          <p class="f-s-15px mb-1">{{ data.notes }}</p>
        </div>
      </div>
    </div>


<!--    <div class="mt-2 d-flex" v-if="data.payment_method && data.payment_method_name">-->
<!--      <div class="w-60per">-->
<!--        <div class="green-cc-3">-->
<!--          <h5 class="color-1a5a4a f-s-22px mb-4"><b>طريقة الدفع</b></h5>-->
<!--          <h5 class="color-1a5a4a f-s-22px mb-4" v-if="is_multi_language_label"><b>Payment method</b></h5>-->
<!--          <div class="d-flex">-->
<!--            <span class="par-h"></span>-->
<!--            <div>-->
<!--              <p class="f-s-19px mb-1">{{ data.payment_method_name }}</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>

export default {
  name: 'TaxInvoiceHeader',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    template: {
      type: Object,
      default: () => ({})
    },
    inContentTemp: {type: Function},
    isMultiLanguageLabel: {type: Boolean},
  },
  data() {
    return {
      base64Image: '',
      is_multi_language_label: false,
    }
  },
  // computed:{
  //     rel_logo: function () {
  //         if(this.data && this.data.company && this.data.company.logo_url){
  //             return this.data.company.logo_url;
  //         }else {
  //             // return window.location.origin + '/media/logos/sajlha_logo_print.png';
  //           return '';
  //         }
  //     }
  // },
  created() {
    this.is_multi_language_label = this.isMultiLanguageLabel ? this.isMultiLanguageLabel : false;
  },
  // methods:{
  //   async convertImageToBase64Image(){
  //     try {
  //       const response = await fetch(this.rel_logo);
  //       const blob = await response.blob();
  //       const reader = new FileReader();
  //
  //       reader.onloadend = () => {
  //         this.base64Image = reader.result;
  //       };
  //
  //       reader.readAsDataURL(blob);
  //
  //     } catch (error) {
  //       console.error('Error converting image to base64:', error);
  //       return null;
  //     }
  //   },
  // }
}
</script>

<style scoped>
.dir-ltr-w-100 {
  direction: ltr;
  float: left;
  text-align: center;
  width: 100%;
}

</style>
