<template>

        <div class="mt-4 table-border-radius break-inside-revert" >
          <table class="table" style="margin: 0;text-align: center;">
            <thead>
            <tr style="background:#ffffff;">
              <!--              <th style="width: 248px;">{{ $t('invoice_sales_print.item') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.price') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.quantity') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.total') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.discount') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.tax_rate') }}</th>-->
              <!--              <th>{{ $t('invoice_sales_print.tax_value') }}</th>-->
              <!--              <th style="width: 152px;">{{ $t('invoice_sales_print.the_total_includes_VAT') }}</th>-->
              <th>#</th>
              <th :style="{width: isCustomProductSizeColumn ? productSizeColumnVal+'%':'179px'}"> {{getLabelShortcut('product_name', 'ar')}} <span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('product_name', 'en')}}</span></th>
              <th>{{getLabelShortcut('quantity', 'ar')}} <span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('quantity', 'en')}}</span></th>
              <th> {{getLabelShortcut('unit_price', 'ar')}}<span  style="display: block; direction: ltr;" v-if="is_multi_language_label">{{getLabelShortcut('unit_price', 'en')}}</span></th>
<!--              <th>الوحدة <span style="display: block;" v-if="is_multi_language_label">Unit</span></th>-->
              <th v-if="inProductTableSetting('unit')">{{getLabelShortcut('unit', 'ar')}} <span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('unit', 'en')}}</span></th>
              <th v-if="inProductTableSetting('discount_calc')">{{getLabelShortcut('discount_calc', 'ar')}} <span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('discount_calc', 'en')}}</span></th>
              <th v-if="inProductTableSetting('subtotal_before_discount')"> {{getLabelShortcut('subtotal_before_discount', 'ar')}}<span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('subtotal_before_discount', 'en')}}</span></th>
              <th v-if="inProductTableSetting('subtotal_after_discount')"> {{getLabelShortcut('subtotal_after_discount', 'ar')}}<span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('subtotal_after_discount', 'en')}}</span></th>
              <th v-if="inProductTableSetting('subtotal_before_tax')"> {{getLabelShortcut('total_before_tax', 'ar')}}<span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('total_before_tax', 'en')}}</span></th>
              <th v-if="inProductTableSetting('tax_applied')"> {{getLabelShortcut('tax_applied', 'ar')}} <span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('tax_applied', 'en')}}</span></th>
              <th  v-if="inProductTableSetting('amount_tax')"> {{getLabelShortcut('tax_val', 'ar')}} <span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('tax_val', 'en')}}</span></th>
              <th  v-if="inProductTableSetting('subtotal_after_tax')"> {{getLabelShortcut('total_after_tax', 'ar')}}<span style="display: block;" v-if="is_multi_language_label">{{getLabelShortcut('total_after_tax', 'en')}}</span></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, index) in itemsList" :key="index">
              <td>{{ index + 1 }}</td>
              <td style="text-align: start !important;">
                <!-- <span style="width: 50px; display: block; word-wrap: unset;white-space: nowrap;text-overflow: ellipsis;"> -->
<!--                <span style="width: 150px; display: block; word-wrap: break-word; white-space: normal;">-->
                  {{ row.item ? row.item.name : '' }}
<!--                  </span>-->
              </td>
              <td>{{ row.qty }}</td>
              <td>{{ row.unit_price }}</td>
              <td v-if="inProductTableSetting('unit')">{{ row.unit_name }}</td>
              <td v-if="inProductTableSetting('discount_calc')">{{ row.discount_calc }}</td>
              <td v-if="inProductTableSetting('subtotal_before_discount')">{{ row.subtotal_before_discount }}</td>
              <td v-if="inProductTableSetting('subtotal_after_discount')">{{ row.subtotal_after_discount }}</td>
              <td v-if="inProductTableSetting('subtotal_before_tax')">{{ row.subtotal_before_tax }}</td>
              <td v-if="inProductTableSetting('tax_applied')">
                <template v-if="row.tax_applied.length > 0">
                  <span v-for="(_row, index) in row.tax_applied" :key="'tax_applied'+index">{{_row.percentage ? parseToShortFloat(_row.percentage) : '0'}} <span v-if='row.tax_applied.length > 1 && (row.tax_applied.length -1) == index'> , </span> </span>
                </template>
                <template v-else-if="row.tax_applied.length == 0 && row.amount_tax > 0 && row.tax">
                  <span> 15 </span>
                </template>
                <span v-else> 0 </span>
              </td>
              <td v-if="inProductTableSetting('amount_tax')">{{ row.amount_tax }}</td>
              <td  v-if="inProductTableSetting('subtotal_after_tax')">{{ row.subtotal_after_tax }}</td>
            </tr>
            <template v-if="itemsList && itemsList.length < 9 && is_empty_row">
              <tr v-for="(row, index) in (8 - itemsList.length)" :key="'empty'+index">
                <td></td>
                <td style="height: 33px;"></td>
                <td></td>
                <td></td>
                <td v-if="inProductTableSetting('unit')"></td>
                <td v-if="inProductTableSetting('discount_calc')"></td>
                <td v-if="inProductTableSetting('subtotal_before_discount')"></td>
                <td v-if="inProductTableSetting('subtotal_after_discount')"></td>
                <td v-if="inProductTableSetting('subtotal_before_tax')"></td>
                <td v-if="inProductTableSetting('tax_applied')"></td>
                <td v-if="inProductTableSetting('amount_tax')"></td>
                <td v-if="inProductTableSetting('subtotal_after_tax')"></td>
              </tr>

            </template>

            </tbody>
          </table>
        </div>
</template>

<script>
import dataTypeNumbers from "@/core/config/mix/dataTypeNumbers";
import invoicesLabelOfItems from "@/core/config/mix/invoicesLabelOfItems";

export default {
    name:'TaxInvoiceProducts',
    props:{
        itemsList:{type:Array, default:[]},
      labelShortcutDetails:{type:Array, default:[]},
      inContentTemp:{type:Function},
      inProductTableSetting:{type:Function},
      isMultiLanguageLabel:{type:Boolean},
      isEmptyRow:{type:Boolean},
      isUseLabelShortcut:{type:Boolean},
      isCustomProductSizeColumn:{type:Boolean},
      productSizeColumnVal:{type: Number},
    },

  methods:{
    ...dataTypeNumbers,
    ...invoicesLabelOfItems,

    getLabelShortcut(_slug, lang){
      let _is_use_label = this.isUseLabelShortcut;
      if(!this.isUseLabelShortcut || !this.isMultiLanguageLabel || !this.isCustomProductSizeColumn){
        _is_use_label = false;
      }
      if(this.isUseLabelShortcut && this.isMultiLanguageLabel && this.isCustomProductSizeColumn && lang == 'en'){
        return '';
      }
      return this.getLabelShortcutFromSettingsPrint( _slug, lang, this.labelShortcutDetails,_is_use_label);
    },
  },
  data(){
      return{
        is_multi_language_label: false,
        is_empty_row: false,
      }
  },
  created(){
    this.is_multi_language_label = this.isMultiLanguageLabel ? this.isMultiLanguageLabel : false;
    this.is_empty_row = this.isEmptyRow ? this.isEmptyRow : false;
  },
}
</script>

<style scoped>

</style>