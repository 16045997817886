import { render, staticRenderFns } from "./TaxInvoiceHeader.vue?vue&type=template&id=98bd7070&scoped=true&"
import script from "./TaxInvoiceHeader.vue?vue&type=script&lang=js&"
export * from "./TaxInvoiceHeader.vue?vue&type=script&lang=js&"
import style0 from "./TaxInvoiceHeader.vue?vue&type=style&index=0&id=98bd7070&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98bd7070",
  null
  
)

export default component.exports